<template>
  <div class="hackathon">
    <section>
      <div class="hackathon-wrapper">
        <img :src="requireAssetsImg('m_hackathon/hackathon-mobile.png')" alt="Hackathon">
        <div class="companyBtn company1Btn">
          <a href="https://xangle.io/" target="_blank"></a>
        </div>
        <div class="companyBtn company2Btn">
          <a href="https://www.krustuniverse.com/" target="_blank"></a>
        </div>
        <div class="companyBtn company3Btn">
          <a href="https://myabcwallet.io/ko/" target="_blank"></a>
        </div>
        <div class="companyBtn company4Btn">
          <a href="https://www.hashed.com/" target="_blank"></a>
        </div>
        <div class="companyBtn company5Btn">
          <a href="https://www.coindeskkorea.com/" target="_blank"></a>
        </div>
        <div class="dreamplusBtn">
          <a href="https://www.dreamplus.asia/" target="_blank"></a>
        </div>
        <div class="mailtoBtn">
          <a href="mailto:dreamplus.hkt@gmail.com" target="_blank"></a>
        </div>
        <div class="applyBtn">
          <button @click="noDisplay()"></button>
        </div>
      </div>
      <!-- <div class="block_top">
        <div class="cont_wrap">
          <img :src="requireAssetsImg('m_hackathon/top.jpg')" alt="Dream Plus">
        </div>
        <div class="blind">
          <h1>Blockchain Hackathon</h1>
          <p>블록체인 업계 현직 개발자 멘토와 함께 팀을 이뤄 2박3일 간 진행되는
            새로운 방식의 해커톤! 드림플러스 블록체인 해커톤에 여러분을 초대합니다.</p>
          <h2>Activity Benefit</h2>
          <sub>해커톤 참가자에게는 아래 혜택이 제공됩니다.</sub>
          <dl>
            <dt>블록체인 네트워크 참여</dt>
            <dd>블록체인 현직자와의 팀 활동 및 네트워킹 기회</dd>
            <dt>IPAD MINI6</dt>
            <dd>블록체인 현직자와의 팀 활동 및 네트워킹 기회</dd>
            <dt>MacBook Pro/Air</dt>
            <dd>해커톤 최우수 및 우수상 수상자 전원에게 맥북 프로/에어 상품수여</dd>
          </dl>
        </div>
      </div>
      <div class="block1">
        <div class="cont_wrap">
          <h2><img :src="requireAssetsImg('m_hackathon/block1.jpg')" alt="Blockchain Hackathon 주제"></h2>
        </div>
        <div class="blind">
          <p>이더리움, 이오스, 파트너사 플랫폼 등을 활용한 자유 주제의 블록체인 웹/앱 서비스 프로토 타입 개발</p>
          <small>NFT, 금융 관련 비즈니스에 대한 기획 요소 반영 시 가점 부여</small>
        </div>
      </div>
      <div class="block2">
        <div class="cont_wrap">
          <h2><img :src="requireAssetsImg('m_hackathon/block2.jpg')" alt="모집 대상"></h2>
        </div>
        <div class="blind">
          <p>2인으로 구성된 팀 단위 지원이 가능합니다.</p>
          <small>대학생, 일반인 모두 참여 가능하며 각 팀은 최소 1인 이상의
            개발자를 포함하고 있어야 합니다. *총 8팀(16인) 선발 예정</small>
          <dl>
            <dt>Developer</dt>
            <dd>블록체인을 활용한 FE/BE 시스템 개발의 역량을 갖고 있는 분</dd>
            <dt>Biz Planner</dt>
            <dd>블록체인의 사상을 이해하고 있으며, 이를 활용한 기획이 가능한 분</dd>
          </dl>
        </div>
      </div>
      <div class="block3">
        <div class="cont_wrap">
          <h2><img :src="requireAssetsImg('m_hackathon/block3.jpg')" alt="모집안내"></h2>
        </div>
        <div class="blind">
          <dl>
            <dt>모집기간</dt>
            <dd>2021년11월17일(WED) - 12월3일(FRI) 11PM</dd>
            <dt>진행과정</dt>
            <dd>11.17 ~ 12.3 모집접수</dd>
            <dd>11.17 ~ 12.9 서류심사</dd>
            <dd>12.10 4PM 최좀 참가자 발표</dd>
            <dd>12.4 참가자 OT 및 담당 멘토 매칭</dd>
            <dd>12.17 ~ 19 해커톤 진행</dd>
            <dd>12.19 결과 발표 및 시상</dd>
            <dt>참가자발표</dt>
            <dd>최종 선발된 팀에 한하여 지원 시 작성해 주신 이메일 및 유선으로 개별 안내
              <small>
                *선발되지 않은 지원자에 대해서는 별도의 안내를 드리지 않습니다.
              </small>
            </dd>
            <dt>지원방법</dt>
            <dd>우측 '공모 지원하기' 버튼을 통한 온리인 지원
              <small>제출된 기획서 및 제안서는 반환되지 않습니다.</small>
            </dd>
          </dl>
        </div>
      </div>
      <div class="block4">
        <div class="cont_wrap">
          <h2><img :src="requireAssetsImg('m_hackathon/block4.jpg')" alt="심사 및 평가 방법"></h2>
        </div>
        <div class="blind">
          <dl>
            <dt>심사위원</dt>
            <dd>드림플러스 블록체인 해커톤 파트너사의 전문가로 구성된 멘토진</dd>
            <dt>서류 평가 기준</dt>
            <dd>아이디어 또는 서비스 모델의 창의성, 구현, 가능성, 기대효과</dd>
            <dt>본선 평가 기준</dt>
            <dd>서비스 모델의 구현 완성도, 비즈니스 가능성, 블록체인 적용 적합성 평가</dd>
          </dl>
        </div>
      </div>
      <div class="block5">
        <div class="cont_wrap">
          <h2><img :src="requireAssetsImg('m_hackathon/block5.jpg')"
            alt="최종 선발팀 대상 블록체인 해커톤 본선(오프라인)"></h2>
        </div>
        <div class="blind">
          <dl>
            <dt>장소</dt>
            <dt>한화생명 드림파크</dt>
            <dd>경기 용인시 기흥구 이현로30번길 45-4</dd>
            <dt>일정</dt>
            <dd>2021.12.17(fri) 5PM ~ 12.19(sun) 2PM</dd>
            <dd>2박3일 전체 일정에 대한 교통 및 숙식이 제공됩니다.</dd>
          </dl>
        </div>
      </div>
      <div class="block6">
        <div class="cont_wrap">
          <h3><img :src="requireAssetsImg('m_hackathon/block6.jpg')"
           alt="주최 - 드림플러스"></h3>
        </div>
      </div>
      <div class="block8 bg_navy">
        <div class="cont_wrap">
          <strong>문의</strong>
          <a href="mailto:dreamplus.hkt@gmail.com" target="_blank" > dreamplus.hkt@gmail.com</a>
        </div>
      </div> -->
    </section>
  </div>
</template>

<script>
import { mixin as mixinHelperUtils } from '@/common/helperUtils';
import StaggerTransition from '@/components/transition/StaggerTransition.vue';
/**
 * Programs > Academy & Internship
 *
 */
export default {
  name: 'MobileHackathon',
  mixins: [
    mixinHelperUtils,
    StaggerTransition,
  ],
  props: {
    accApplyPgmMngList: {
      type: Array,
      default: () => [],
    },
    isApplyCompleted: {
      type: Boolean,
      default: false,
    },
  },
  head: {
    meta: [
      { name: 'viewport', content: 'width=device-width,initial-scale=1.0,minimum-scale=1.0,maximum-scale=2.0' },
    ],
  },
  data() {
    return {
      visual_intro: 'visual_intro',
    };
  },
  mounted() {
    if (window.location.pathname === '/programs/hackathon'
      && (sessionStorage.getItem('hackerton') === undefined || sessionStorage.getItem('hackerton') === null)) {
      this.visual_intro = 'novisual_intro';
    }
  },
  methods: {
    noDisplay() {
      this.showAlert('공모 지원은 PC 환경에서만 지원 가능합니다. PC 화면에서 진행해 주시기 바랍니다.');
    },
  },
};
</script>
<style>
.hackathon .popup {width: initial !important;}
.hackathon img{width:100%; max-width: inherit;}
.hackathon .cont_wrap { margin:0 auto; text-align: center;}
.hackathon .bg_img{ background-size: 100% 100%;}
.hackathon .bg_navy{ background-color: #0A141E;}
.hackathon .visual_intro, .hackathon .novisual_intro{ height: auto;}
.hackathon .block5{padding: 0;}
.hackathon .block6{ overflow: hidden; }
.hackathon .block8{ padding: 20px 0; font-size: 14px; color: #fff;}
.style_hackathon footer{ display: none;}
.style_hackathon .scroll-top{ display: none;}
.style_hackathon .header{ display: none;}
.style_hackathon .visual_intro{ padding-top: 0;}
.wrap.style_hackathon{ min-width: 320px;}
.floating-wrap {position:absolute; display:none; top:80px; right:14px; z-index:100;}
.floating-wrap.on {display:block;}
.btn-floating {position:relative; display:inline-block; width: 152px; min-width: 58px;
height:58px; padding:0 0 0 48px; box-sizing: border-box;
font-size: 16px; font-weight: 700; line-height: 1.29; letter-spacing: normal; text-align: center;
color:#fff; border-radius:29px; background:#ff5013; z-index:10; cursor:pointer;
box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.22); transition:.7s;
transition: width 0.3s ease;
overflow: hidden;
}
.btn-floating .text {
  display: inline-block; width: 73px; margin-right: 21px; font-size: 13px; line-height: 58px;
  opacity: 1;
  transition: opacity 0.3s ease;
}
.btn-floating:before {content:''; display:inline-block; width:28px; height:28px; vertical-align: middle;
position: absolute; left: 13px; top: 50%; transform: translateY(-50%); margin-bottom: 0;
background:url('../../assets/images/icons/icon-floating.png') no-repeat center center/cover;}
.btn-floating:hover {background:#BD3B0E;}
.btn-floating > span {word-break: keep-all;}
.btn-next:hover, .btn-prev:hover {background:rgba(255,255,255,.6);}
.floating-wrap.apply {width:auto; height:58px;align-items: center; justify-content: center; top: 24px;}
.floating-wrap.apply.on {display:flex; position: fixed;}
.floating-wrap.apply .btn-floating {background:#3190d6; padding-left:58px;}
.floating-wrap.apply .btn-floating:hover {background:#246a9e;}
.floating-wrap.apply .btn-floating:before {
  width: 32px;
  height: 32px;
  background-image:url('../../assets/images/icons/btn-apply.png');
  margin-bottom: 0;
  top: 50%;
  }
.floating-wrap.apply .rotate-circle {position:absolute; top:0; left:0; transform: scale(1.35);}
.floating-wrap.apply .rotate-circle .caption {font-size:8px; font-weight:300; letter-spacing:-0.2px;
  fill:#3287c6; overflow:hidden; transform-origin: 50% 50%; animation: rotate 10s linear infinite;}
.floating-wrap.apply:hover .rotate-circle .caption {fill:#246a9e;}
.floating-wrap.scrolling .btn-floating {width: 58px; overflow: hidden;}
.floating-wrap.scrolling .btn-floating .text{ opacity: 0;}
.rotate-circle{ display: none;}
.popup{ width:85%; box-sizing: border-box; margin: 0 auto; padding: 30px;}
.vm--container.scrollable .vm--modal{background-color: transparent;}
.vm--overlay{ background-color: rgba(0, 0, 0, 0.4);}
.popup .popup-contents .message{ font-size: 16px;}
.wrap.hackathon #content{padding-bottom: 0;}
.wrap.hackathon .scroll-top.upper{ margin-bottom: 6rem;}

.hackathon-wrapper {position: relative;}
.applyBtn {
  position: absolute;
  bottom: calc(5%);
  width: 100%;
  height: calc(1.12%);
  /* background: #7fffd459; */
}
.applyBtn button {
  display: block;
  height: 100%;
  margin: 0 auto;
  width: 41%;
  transform: translatex(-5.4%);
  cursor: pointer;
  /* background: #00ffff36; */
}
.mailtoBtn {
  position: absolute;
  bottom: calc(0.89%);
  width: 100%;
  height: calc(0.7%);
}
.mailtoBtn a{
  display: block;
  height: 100%;
  margin: 0 auto;
  width: 54%;
  /* background: #7fffd459; */
}
.dreamplusBtn {
  position: absolute;
  bottom: calc(1.9%);
  width: 100%;
  height: calc(1.2%);
}
.dreamplusBtn a {
  display: block;
  height: 100%;
  margin: 0 auto;
  width: 35%;
  /* background: #00ffff5e; */
}
.companyBtn {
  position: absolute;
  width: 100%;
  height: calc(1.1%);
}
.company1Btn {
  bottom: calc(36.7%);
}
.companyBtn a{
  display: block;
  height: 100%;
  width: 39%;
  margin-left: 11%;
  /* background-color: #007dd748; */
}
.company2Btn {
  bottom: calc(31.7%);
}
.company3Btn {
  bottom: calc(26.7%)
}
.company4Btn {
  bottom: calc(21.7%);
}
.company5Btn {
  bottom: calc(16.7%);
}
.floating-wrap.apply .btn-floating  {
  background: #007cd7 !important;
}
.floating-wrap.apply .btn-floating:hover {
  background: #246a9e !important;
}
.floating-wrap.apply .rotate-circle .caption {
  fill: #007cd7 !important;
}
</style>
