var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hackathon"},[_c('section',[_c('div',{staticClass:"hackathon-wrapper"},[_c('img',{attrs:{"src":_vm.requireAssetsImg('m_hackathon/hackathon-mobile.png'),"alt":"Hackathon"}}),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_c('div',{staticClass:"applyBtn"},[_c('button',{on:{"click":function($event){return _vm.noDisplay()}}})])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"companyBtn company1Btn"},[_c('a',{attrs:{"href":"https://xangle.io/","target":"_blank"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"companyBtn company2Btn"},[_c('a',{attrs:{"href":"https://www.krustuniverse.com/","target":"_blank"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"companyBtn company3Btn"},[_c('a',{attrs:{"href":"https://myabcwallet.io/ko/","target":"_blank"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"companyBtn company4Btn"},[_c('a',{attrs:{"href":"https://www.hashed.com/","target":"_blank"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"companyBtn company5Btn"},[_c('a',{attrs:{"href":"https://www.coindeskkorea.com/","target":"_blank"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dreamplusBtn"},[_c('a',{attrs:{"href":"https://www.dreamplus.asia/","target":"_blank"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mailtoBtn"},[_c('a',{attrs:{"href":"mailto:dreamplus.hkt@gmail.com","target":"_blank"}})])
}]

export { render, staticRenderFns }